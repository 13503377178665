import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Gallery } from "../components/gallery/Gallery"
import { Router } from "@reach/router"

const IndexPage = props => {
  const serverData = useStaticQuery(graphql`
    query mainLayout {
      allContentfulLayout(filter: { name: { eq: "Main" } }) {
        nodes {
          data {
            thumbnail {
              fluid {
                src
              }
            }
            contiImages {
              fixed(width: 3000) {
                src
              }
            }
            mobileContiImages {
              fixed(width: 850) {
                src
              }
            }
            images {
              fluid {
                src
              }
            }
            title
          }
          name
        }
      }
    }
  `)

  return (
    <>
      <SEO title="홈" />
      <Gallery contiList={serverData.allContentfulLayout.nodes[0].data} />
    </>
  )
}

const Hello = () => {
  return <div>hello</div>
}

export default IndexPage
