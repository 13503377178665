import React, { useEffect, useRef, useState, useContext } from "react"
import styled from "styled-components"
import "../../styles/swiper.min.css"
import { Viewer } from "../viewer"
import { device } from "../../styles/device"

import { navigate } from "gatsby"
import { usePrevious } from "../../utils/usePrevious"
import { MyContext } from "../layout"
interface Props {
  contiList: any
}

export const Gallery: React.FC<Props> = ({ contiList }) => {
  const { showViewer } = useContext(MyContext)
  const prev = usePrevious(showViewer)

  return (
    <>
      <Wrapper className="viewer" showViewer={showViewer}>
        {contiList.map((node, idx) => {
          return <ViewerItem key={`${node.title}-${idx}`} node={node} />
        })}
      </Wrapper>

      {/* {showViewer && (
        <Viewer node={activeConti} setShowViewer={setShowViewer} />
      )} */}
    </>
  )
}

const ViewerItem = ({ node }) => {
  const { setShowViewer, setActiveConti } = useContext(MyContext)
  return (
    <ViewerItemWrapper>
      <ImageOverflowWrapper style={{ overflow: "hidden" }}>
        <img
          onClick={() => {
            setActiveConti(node)
            navigate("/viewer")
          }}
          src={node.thumbnail.fluid.src}
        />
      </ImageOverflowWrapper>
      <span>{node.title}</span>
    </ViewerItemWrapper>
  )
}

const Wrapper = styled.div<{
  showViewer: boolean
}>`
  display: ${({ showViewer }) => (showViewer ? "none" : "flex")};
  flex-wrap: wrap;
  justify-content: space-between;

  img {
    width: 100%;
  }

  &::after {
    content: "";
    flex: 0 0 32%;
  }
`

const ViewerItemWrapper = styled.div`
  display: inline-block;
  width: 49%;
  font-size: 11px;
  cursor: pointer;
  margin-bottom: 14px;

  @media ${device.tablet} {
    width: 32%;
    font-size: 12px;
    margin-bottom: 20px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    margin-bottom: 20px;

    img:hover {
      transform: scale(1.2);
      transition: transform 1.5s linear;
    }
  }

  span {
    color: #eaeaea;
    opacity: 0.8;
  }
`

const ImageOverflowWrapper = styled.div`
  margin-bottom: 4px;
  @media ${device.mobileL} {
    margin-bottom: 8px;
  }
`
